import React from "react";
import { Link } from "gatsby";

const Button = ({
  text,
  href,
  desktop,
  mobile,
  show_arrow = true,
}) => {
  const content = text.split(" "),
    strong = content.shift(),
    rest = content.join(" ");
  return (
    <Link
      to={`${href}`}
      className={`btn-red ${desktop ? "btn-desktop" : ""} ${
        mobile ? "btn-mobile" : ""
      }`}
    >
      <span>
        <strong>{strong}</strong>
        {` ${rest}`}
      </span>
      {show_arrow && (
        <img
          src={require("../images/arrow-button.svg")}
          style={{ width: "23px" }}
          alt="white arrow"
        />
      )}
    </Link>
  );
};

export default Button;
