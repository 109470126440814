import React, { Component } from "react";
import slugify from "slugify";

import Breadcrumbs from "../components/breadcrumbs";
import Header from "../components/header";
import Layout from "../components/layout";
import ReferenceBox from "../components/reference_box";

class References extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.pageContext.data,
      references: this.props.pageContext.references,
      lang: this.props.pageContext.lang,
      hrefLang: this.props.pageContext.data.hrefLang,
      breadcrumbs: this.props.pageContext.breadcrumbs,
      menu: this.props.pageContext.menu,
      footerMenu: this.props.pageContext.footerMenu,
    };
  }
  render() {
    const {
      breadcrumbs,
      references,
      lang,
      menu,
      hrefLang,
      footerMenu,
      data: { seoTitle, h1_reference, references_description, slug },
    } = this.state;
    return (
      <Layout menu={menu} footerMenu={footerMenu} title={seoTitle}>
        <div className="bg-gradient">
          <Header lang={lang} hrefLang={hrefLang} />
          <Breadcrumbs data={breadcrumbs} lang={lang} />
          <div className="main-wrapper-md">
            <section className="section-header-image">
              <div className="text-holder">
                <header className="section-header red-line">
                  <h1>
                    <strong>{h1_reference}</strong>
                  </h1>
                </header>
                <p>{references_description}</p>
              </div>
              <div className="image-holder">
                <img src={require("../images/references-header.svg")} alt="" />
              </div>
            </section>
            <section className="references-list">
              <div className="references-list-wrapper">
                {references &&
                  references.map(({ id, name, logo, shortText }) => (
                    <ReferenceBox
                      key={id}
                      img={logo}
                      description={shortText}
                      href={`/${lang}/${slug}/${slugify(name, {
                        replacement: "-",
                        remove: null,
                        lower: true,
                      })}`}
                      imageTitle={name}
                    />
                  ))}
              </div>
              {/* <Pagination /> */}
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default References;
